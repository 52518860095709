<template>
  <div class="home">
    <img src="../../assets/wyys.png" alt="">
  </div>
</template>
<style lang="less">
.home{
    width: 100%;
    height: 100%;
  overflow-y: scroll;
  img {
    width: 100%;
  }
}
</style>